import React from "react";
import { Toaster } from "react-hot-toast";
import TrilliumLogo from "./assets/Trillium-health-logo.png";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import Login from "./Containers/Login";
import { Typography } from "@material-ui/core";
import PaymentDetails from "Containers/PaymentDetails";
import "./GlobalStyles.css";

function App() {
  return (
    <div className="app">
      <header className="trillium-health-logo-header">
        <div className="trillium-health-logo-container">
          <a href="https://trillium.health/">
            <img
              src={TrilliumLogo}
              alt="trillium-health-logo"
              className="trillium-health-logo"
            />
          </a>
        </div>
      </header>
      <Toaster position="top-right" reverseOrder={false} />
      <BrowserRouter>
        <React.Suspense fallback={"loading"}>
          <Switch>
            <Route path="/payment" component={PaymentDetails} />
            <Route path="/" component={Login} />
            <Redirect from="/" to="/" />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
      <footer className="footer_container">
        <div className="copyright">
          <Typography variant="caption" align="center" color="textSecondary">
            Copyright &copy; {new Date().getFullYear()}-
            {new Date().getFullYear() + 1} trillium.health
          </Typography>
        </div>
      </footer>
    </div>
  );
}

export default App;
