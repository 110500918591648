import { ofType } from "redux-observable";
import { switchMap, map, takeUntil } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
// import { ajax as utlAjax } from "Utils";
import environment from "environments/environment";
// import { Apis } from "Redux/APIs";
import { LOGIN, GET_LOGIN_DATA, REQUEST_CANCEL } from "./Login.types";
import { loginResponse, getLoginDataResponse } from "./Login.actions";
const { baseUrl } = environment;
const LoginEpic = {};

LoginEpic.login = (action$) =>
  action$.pipe(
    ofType(LOGIN),
    switchMap((action) =>
      ajax({
        // headers: {
        //   "application-id": 2,
        //   "Content-Type": "application/json",
        // },
        url: `${baseUrl}/trillium-patient-service/v1/checkin/${action.payload.dob}?appointmentPinNo=${action.payload.pin}`,
        method: "POST",
        // body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => loginResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

LoginEpic.getLoginData = (action$) =>
  action$.pipe(
    ofType(GET_LOGIN_DATA),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/trillium-patient-service/v1/checkin/login/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getLoginDataResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

export default LoginEpic;
