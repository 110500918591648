import { combineEpics } from "redux-observable";
import LoginEpic from "./Login/Login.epic";
import CheckinEpic from "./Checkin/checkin.epic";

const rootEpic = combineEpics(
  LoginEpic.login,
  LoginEpic.getLoginData,
  CheckinEpic.getPaymentDetails,
);

export default rootEpic;
