import { ofType } from "redux-observable";
import { switchMap, map, takeUntil } from "rxjs/operators";
import { ajax as utlAjax } from "Utils";
import environment from "environments/environment";
import { GET_PAYMENT_DETAILS, REQUEST_CANCEL } from "./checkin.types";
import { getPaymentDetailsRes } from "./checkin.actions";
const { baseUrl } = environment;
const CheckinEpic = {};

CheckinEpic.getPaymentDetails = (action$) =>
  action$.pipe(
    ofType(GET_PAYMENT_DETAILS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/trillium-patient-service/v1/checkin/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getPaymentDetailsRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

export default CheckinEpic;
