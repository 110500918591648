import { LOGIN_RES, GET_LOGIN_DATA_RES } from "./Login.types";

const INITIAL_STATE = {
  loginResponse: {},
  getLoginData: {},
};

const LoginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_RES: {
      return { ...state, ...{ login: true, loginResponse: action.payload } };
    }
    case GET_LOGIN_DATA_RES: {
      return { ...state, ...{ login: true, getLoginData: action.payload } };
    }
    default:
      return state;
  }
};

export default LoginReducer;
