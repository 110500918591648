import React, { useEffect, useState } from "react";
// import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Grid, Typography } from "@material-ui/core";
import { getLoginData, login } from "Redux/Login/Login.actions";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker from "components/DatePicker";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  root: {
    // '@media(min-width: 960px)': {
    //   display: 'flex',
    //   justifyContent: 'center',
    //   height: '100vh',
    //   width: '100%',
    // },
  },
  header: {
    minHeight: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #e0e0e0",
  },
  header__bar: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "100%",
    width: "90%",
  },
  logo: {
    width: "180px",
  },
  containerWrapper: {
    display: "flex",
    justifyContent: "center",
    "@media(max-width: 200px)": {
      display: "block",
      height: "fit-content",
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "2rem",
    "@media(min-width: 760px)": {
      height: "75%",
      width: "400px",
    },
  },
  name: {
    display: "flex",
    justifyContent: "start",
    textAlign: "start",
    margin: "4rem 0 1rem",
    color: "#14A6A6",
    fontWeight: "500",
    fontSize: "36px",
    "@media(min-width: 760px)": {
      justifyContent: "center",
      textAlign: "center",
    },
  },
  subHeading: {
    margin: "0 0 3rem",
    color: "#4F566B",
    fontSize: "22px",
    fontWeight: "400",
    lineHeight: "24px",
    "@media(min-width: 760px)": {
      justifyContent: "center",
      textAlign: "center",
    },
  },
  clinicName: {
    fontWeight: "600",
  },
  dob: {
    margin: "0 0 1rem",
  },
  confirmBtn: {
    margin: "4rem 0 1rem",
    width: "100%",
    color: "white !important",
  },
  copyright: {
    padding: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
}));

function Login(props) {
  const classes = useStyles();
  let a = window.location.href.split("/").pop();
  const [patientName, setPatientName] = useState("");
  const [clinicName, setClinicName] = useState("");
  const [dob, setDob] = useState(null);
  const [dobError, setDobError] = useState(false);

  const formatDate = (date) => {
    if (date === null) {
      return null;
    }
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    if (isNaN(year)) {
      return null;
    } else {
      return [year, month, day].join("-");
    }
  };

  function handledate(e) {
    setDob(e);
  }

  const handleConfirm = () => {
    if (dob) {
      props.login({
        dob: formatDate(dob),
        pin: a.toString(),
      });
    } else {
      setDobError(true);
    }
  };

  useEffect(() => {
    props.getLoginData(a.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.loginData &&
      props.loginData.getLoginData &&
      props.loginData.getLoginData.response
    ) {
      if (props.loginData.getLoginData.response.responseCode === 0) {
        let data = props.loginData.getLoginData.response.data;
        setPatientName(data.patientName);
        setClinicName(data.locationName);
        localStorage.setItem("apmtTypeId", data.appointmentTypeId);
        localStorage.setItem("checkin", data.checkInEnabled);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loginData.getLoginData.response]);

  useEffect(() => {
    if (
      props.loginData &&
      props.loginData.loginResponse &&
      props.loginData.loginResponse.response
    ) {
      if (props.loginData.loginResponse.response.responseCode === 0) {
        let data = props.loginData.loginResponse.response.data;
        localStorage.setItem("accessToken", data.accessToken);
        window.location.href = "/details";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loginData.loginResponse.response]);

  return (
    <div className={classes.root}>
      <div className={classes.containerWrapper}>
        <Grid container className={classes.container}>
          <Grid xs={12} sm={12} item>
            <Typography className={classes.name}>{patientName}</Typography>
          </Grid>
          <Grid xs={12} sm={12} item>
            <p className={classes.subHeading}>
              Confirm your date of birth to view the payment details for your
              appointment with{" "}
              <span className={classes.clinicName}>{clinicName}</span>
            </p>
          </Grid>

          <Grid xs={12} sm={12} item>
            <div className="dob_label">Date of birth</div>
          </Grid>

          <Grid xs={12} sm={12} item>
            <div className="dob_textbox">
              <DatePicker
                // required
                value={dob}
                // label="Date of birth"
                // variant="outlined"
                variant="standard"
                onChange={handledate}
                maxDate={new Date()}
                error={dobError}
              />
            </div>
          </Grid>
          <Grid xs={12} sm={12} item>
            <Button
              className={classes.confirmBtn}
              variant="contained"
              color="primary"
              size="large"
              onClick={handleConfirm}
            >
              Authenticate
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginData: state.login,
});

const mapDispatchToProps = (dispatch) => ({
  getLoginData: (values) => dispatch(getLoginData(values)),
  login: (values) => dispatch(login(values)),
});

Login.propTypes = {
  getLoginData: PropTypes.func,
  login: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
