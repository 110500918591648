import { GET_PAYMENT_DETAILS_RES } from "./checkin.types";

const INITIAL_STATE = {
  paymentDetails: {},
};

const CheckinReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENT_DETAILS_RES: {
      return { ...state, ...{ paymentDetails: action.payload } };
    }
    default:
      return state;
  }
};

export default CheckinReducer;
