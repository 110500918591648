import {
  LOGIN,
  LOGIN_RES,
  GET_LOGIN_DATA,
  GET_LOGIN_DATA_RES,
} from "./Login.types";

export const login = (params) => ({
  type: LOGIN,
  payload: params,
});

export const loginResponse = (data) => ({
  type: LOGIN_RES,
  payload: data,
});

export const getLoginData = (params) => ({
  type: GET_LOGIN_DATA,
  payload: params,
});

export const getLoginDataResponse = (data) => ({
  type: GET_LOGIN_DATA_RES,
  payload: data,
});
