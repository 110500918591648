import {
  GET_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS_RES,
} from "./checkin.types";

export const getPaymentDetails = (params) => ({
  type: GET_PAYMENT_DETAILS,
  payload: params,
});

export const getPaymentDetailsRes = (data) => ({
  type: GET_PAYMENT_DETAILS_RES,
  payload: data,
});