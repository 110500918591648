import { combineReducers } from "redux";
import LoginReducer from "./Login/Login.reducer";
import CheckinReducer from "./Checkin/checkin.reducer";

const rootReducer = combineReducers({
  login: LoginReducer,
  checkin: CheckinReducer,
});

export default rootReducer;
